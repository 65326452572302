<template>
  <section class="w-full py-8">
    <NPArticleContentBlockContent
      v-if="content && content.length > 0"
      :blocks="content"
      :gdpr-settings="gdprSettings"
    />
  </section>
</template>

<script setup lang="ts">
import GetPage from '~/graphql/queries/GetPageBySlug.gql'

const props = withDefaults(defineProps<{
  slug?: string
}>(), {
  slug: '',
})

const { data } = useAsyncQuery<PageResponse>(GetPage, {
  slug: props.slug,
})

const content = computed(() => {
  return data.value?.page?.blocks ?? []
})

const gdprSettings = useGdpr().getSettings()
</script>
