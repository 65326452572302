<template>
  <div class="max-w-screen-sm px-4 py-8 mx-auto md:px-0">
    <SinglePage v-if="slug" :slug="slug" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()

const slug = computed(() => route.params.page as string ?? '')
</script>
